@mixin heroHeights() {
  height: 340px;
  position: relative;

  @include breakpoint($bp-medium) {
    height: 70vh;
  }

  @include breakpoint($bp-large) {
    height: 87vh;
  }
}

.c-hero {
  text-align: center;
  margin: 60px /* Top bar on mobile height */ auto 0 auto;
  max-width: $max-site-width;
  min-width: 320px;
  position: relative;
  @include heroHeights();

  @include breakpoint($bp-large) {
    margin: 0 auto;
  }

  &--shorter {
    @include breakpoint($bp-large) {
      height: 50vh;
      min-height: 400px;
    }

    .c-hero__inner,
    .c-hero__content {
      @include breakpoint($bp-large) {
        height: 50vh;
        min-height: 400px;
      }
    }
  }

  &__title {
    @extend %t-merriweather-xlarge-light;
    font-size: toRems(34px);
    position: absolute;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    top: 50%;
    transform: translateY(calc(-50% + 20px)) translateX(-50%);
    width: 80%;
    left: 50%;

    @include breakpoint($bp-large) {
      font-size: toRems(54px);
    }
  }

  &__subtitle {
    font-size: toRems(18px);
    position: absolute;
    top: 80%;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    width: 100%;
  }

  &__image {
    background-position: 50% 0;
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &__inner {
    overflow: hidden;

    @include breakpoint($bp-medium) {
      position: fixed;
      width: 100%;
      max-width: $max-site-width;
      height: 70vh;
    }

    @include breakpoint($bp-large) {
      height: 90vh;
    }

    &:before {
      background-color: rgba(0, 0, 0, 0.6);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;

      @include breakpoint($bp-large) {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  &__content {
    color: $c-white;
    position: relative;
    z-index: 2;
    padding-top: var(--hero-padding-top, 0);

    @include heroHeights();

    @include breakpoint($bp-large) {
      padding-top: var(--hero-padding-top-desktop, 0);
    }
  }

  &__logo {
    margin: 0;
    width: 400px;
    height: 120px;
    max-width: 80%;
    margin: 0 auto;

    @include breakpoint($bp-medium) {
      width: 600px;
      margin-top: 20px;
    }

    @include breakpoint($bp-large) {
      width: 800px;
      height: 125px;
    }

    svg {
      width: 100%;
    }
  }

  &__address {
    @extend %t-merriweather-mid-large-light;
    width: 100%;
    margin-top: $spacing-small;

    @include breakpoint($bp-medium) {
      margin-top: $spacing-large;
    }

    @include breakpoint($bp-large) {
      display: none;
    }
  }

  blockquote {
    font-family: $georgia;
    quotes: '\201C''\201D';
    display: none;
    margin: 0;

    @include breakpoint($bp-large) {
      display: block;
      position: absolute;
      left: 74%;
      width: 20%;
      top: 50%;
      transform: translateY(-50%);
      max-width: 220px;
    }
    p {
      display: inline;
      font-style: italic;
      line-height: 1;

      &:before {
        color: $c-white;
        content: open-quote;
        font-size: 3em;
        line-height: 0.1em;
        vertical-align: -0.4em;
        padding-right: 0.15em;
      }

      &:after {
        color: $c-white;
        content: close-quote;
        font-size: 3em;
        line-height: 0.1em;
        vertical-align: -0.6em;
      }

      @include breakpoint($bp-large) {
        font-size: 18px;
      }
    }

    footer {
      font-size: toRems(13px);
      font-family: $lato;
    }
  }

  .c-social {
    bottom: $spacing-base;
    position: absolute;
    width: 100%;
  }

  .o-slideshow {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &__item {
      height: 100%;
      position: absolute;
      max-height: none;
      opacity: 0;
      transition: opacity 1.5s ease-in-out;

      &:first-child {
        // Make the first fading image appear straight away
        opacity: 1;
      }
    }
  }

  &--centered {
    .c-hero__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0;
    }

    .c-hero__logo {
      max-width: 100%;
      width: calc(100% - 30px);
      padding-left: $spacing-small;
      padding-right: $spacing-small;
      height: auto;
      max-width: none;

      @include breakpoint($bp-small) {
        width: 500px;
      }

      @include breakpoint($bp-medium) {
        margin: 0;
        width: 700px;
        margin-top: 20px;
      }

      @include breakpoint($bp-large) {
        width: 968px;
        height: auto;
      }

      svg {
        width: 100%;
        display: block;
      }
    }
  }
}
