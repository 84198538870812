.o-logo-list {
    
    @include breakpoint($bp-small) {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 900px;
        margin: 0 auto;
        flex-wrap: wrap;
    }   
       
    li {
        text-align: center;
        margin-bottom: $spacing-large;
        
        @include breakpoint($bp-small) {
            flex: auto;
            width: 50%;
        }  
        
        @include breakpoint($bp-large) {
            width: auto;
        }  
        
        img {
            margin: 0 auto;
        }
    }
}