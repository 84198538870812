.o-full-width-image {
  position: relative;

  &__inner {
    overflow: hidden;

    @include breakpoint($bp-large) {
      height: 70vh;
      min-height: 800px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }

  &__content {
    background-color: var(--full-page-image-content-bg-color);
    color: palette(grey, xx-light);
    max-width: 664px;
    padding: $spacing-base;
    position: relative;
    z-index: 2;

    p {
      line-height: 1.8;

      @include breakpoint($bp-large) {
        font-size: $font-size-5;
      }
    }

    @include breakpoint($bp-large) {
      position: absolute;
      left: 0;
      top: $spacing-large;
      padding: $spacing-xlarge;
    }

    .c-btn {
      margin-top: $spacing-base;
    }
  }

  .js-parallax-section & {
    &__image {
      height: 200%;
      top: -100%;
      position: relative;
    }
  }
}
