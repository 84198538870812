$max-item-width: 520px;

.c-menu-section {
  text-align: center;
  max-width: 1024px;
  margin: 0 auto $spacing-large;
  position: relative;
  padding-top: $spacing-large;

  &:first-of-type {
    padding-top: $spacing-medium;
  }

  @include breakpoint($bp-large) {
    margin: 0 auto 70px;
    padding-top: 70px;
  }

  &--full-width {
    max-width: none;
  }

  &:last-child {
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -55px;
    height: 1px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    background-color: palette(grey, x-light);

    @include breakpoint($bp-large) {
      bottom: -76px;
    }
  }

  .flickity-page-dots {
    bottom: -5px;

    .dot {
      width: 6px;
      height: 6px;
    }
  }

  &__header {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-base;

    @include breakpoint($bp-large) {
      margin-bottom: $spacing-large;
    }

    @include breakpoint($bp-large) {
      font-size: toRems(18px);
    }
  }

  &__list {
    display: grid;
    grid-gap: $spacing-medium;
    padding-top: $spacing-medium;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint($bp-medium-max) {
      max-width: $max-item-width;
    }

    h4 {
      @extend %t-merriweather-large-light;
      margin-bottom: 0.3em;
      font-size: toRems(20px);
      font-weight: $font-weight-normal;

      @include breakpoint($bp-large) {
        font-size: $font-size-3;
      }

      + p {
        font-size: $font-size-5;
      }
    }

    li {
      align-items: flex-start;
      height: 100%;

      // Randomise a slight rotation on each item
      &:nth-child(3n + 1) {
        .c-menu-section__image {
          transform: rotate(-1.2deg);
        }
      }

      &:nth-child(2n + 3) {
        .c-menu-section__image {
          transform: rotate(1.2deg);
        }
      }

      &:nth-child(3n + 2) {
        .c-menu-section__image {
          transform: rotate(1.2deg);
        }
      }
    }

    .c-menu-section__info {
      font-size: toRems(16px);
      line-height: 1.7;
    }

    @include breakpoint($bp-medium) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $spacing-medium;
      list-style: none;
      align-items: flex-start;
    }

    @include breakpoint($bp-large) {
      grid-gap: $spacing-large $spacing-xlarge;
    }
  }

  &__image {
    position: relative;
    background-color: palette(grey, xx-light);
    padding: $spacing-small;
    margin: 0 0 $spacing-medium;
    display: inline-block;
    transition: background-color 0.3s $trans-ease-out-quartic;

    &:hover {
      background-color: palette(pink);

      img {
        transform: scale(1.05) rotate(0.5deg);
      }
    }

    @include breakpoint($bp-medium) {
      padding: $spacing-base;
      margin: 0 $spacing-small $spacing-medium;
    }

    @include breakpoint($bp-large) {
      margin: 0 $spacing-large $spacing-medium;
    }

    > div {
      position: relative;
      z-index: 2;
      border-radius: 6px;
      overflow: hidden;
    }

    img {
      transition: all 0.3s $trans-ease-out-quartic;
      display: block;
      margin: auto;
      position: relative;
      z-index: 1;

      // Put a solid shadow behind the image
      box-shadow: 0 0 0 3px $c-white;
    }

    &--featured {
      transform: rotate(-0.8deg);
      margin-top: $spacing-base;
      max-width: $max-item-width;

      @include breakpoint($bp-medium) {
        max-width: 420px;
      }
    }
  }

  > p {
    @include breakpoint($bp-large) {
      font-size: toRems(18px);
    }
  }

  &__summary {
    @include breakpoint($bp-large) {
      font-size: $font-size-4;
      line-height: 1.4;
    }
  }

  &__title {
    @extend %t-lato-large-bold;

    + p:not(.c-menu-section__summary) {
      font-size: $font-size-4;
      font-weight: $font-weight-bold;
      line-height: 1.4;
      text-transform: none;
      color: palette(grey, mid-light);

      @include breakpoint($bp-large) {
        margin-bottom: $spacing-large;
      }
    }

    + .c-menu-section__summary {
      @include breakpoint($bp-large) {
        margin-top: $spacing-medium;
      }
    }
  }

  > li {
    position: relative;
    margin-bottom: 70px;

    &:last-child {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }

    &:after {
      @extend %t-merriweather-mid;
      content: '•';
      position: absolute;
      color: palette(pink);
      left: 50%;
      bottom: -50px;
    }
  }

  &__info {
    @extend %t-merriweather-mid;
    color: palette(grey, light);
    position: relative;

    @include breakpoint($bp-medium-max) {
      font-size: toRems(15px) !important;
    }
  }
}
