.o-thirds-slideshow {
    margin-bottom: 25px;
    
    &__item {
        width: 100%;
        padding: $spacing-medium;
        opacity: .4;
        transition: opacity 600ms ease-in-out;
        
        &.is-selected {
            opacity: 1;
        }
        
        @include breakpoint($bp-large) {
            @include span-columns(4);    
            padding: $spacing-large;
        }      
    }
}