/**
 * Generated SVG Sprite Sizes
 *
 * DO NOT EDIT THIS FILE IT IS FOR REFERENCE ONLY
 *
 * This sprites Sass file is generated by Gulp
 * To add more icons to the svg spritesheets:
 *    1. Add svgs to "images/svg/sprites/"
 *    2. Run `gulp svg-sprites` in your terminal
 *
 */

%svg-icon {
	display: inline-block;
}

.svg-arrow-left {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-arrow-right {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-chevron-down {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-chevron-left {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-chevron-right {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-chevron-up {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-criss-cross {
	@extend %svg-icon;
	height: 20px;
	width: 19px;
}

.svg-embers-logo-icon {
	@extend %svg-icon;
	height: 48px;
	width: 32px;
}

.svg-embers-logo-white {
	@extend %svg-icon;
	height: 29px;
	width: 529px;
}

.svg-embers-logo-with-text {
	@extend %svg-icon;
	height: 157px;
	width: 860px;
}

.svg-facebook {
	@extend %svg-icon;
	height: 54px;
	width: 30px;
}

.svg-facebook-circle {
	@extend %svg-icon;
	height: 35px;
	width: 35px;
}

.svg-google-plus {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-instagram {
	@extend %svg-icon;
	height: 35px;
	width: 35px;
}

.svg-instagram-alt {
	@extend %svg-icon;
	height: 51px;
	width: 51px;
}

.svg-mag-glass {
	@extend %svg-icon;
	height: 22px;
	width: 22px;
}

.svg-mail {
	@extend %svg-icon;
	height: 21.2px;
	width: 30px;
}

.svg-pinterest {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-search {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-shambles-logo {
	@extend %svg-icon;
	height: 206.996px;
	width: 893.657px;
}

.svg-shambles-logo-icon {
	@extend %svg-icon;
	height: 42px;
	width: 42px;
}

.svg-share {
	@extend %svg-icon;
	height: 46px;
	width: 46px;
}

.svg-star {
	@extend %svg-icon;
	height: 18px;
	width: 19px;
}

.svg-star-half {
	@extend %svg-icon;
	height: 18px;
	width: 19px;
}

.svg-tick {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-twitter {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-twitter-circle {
	@extend %svg-icon;
	height: 35px;
	width: 35px;
}

.svg-vimeo {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

.svg-youtube {
	@extend %svg-icon;
	height: 100px;
	width: 100px;
}

