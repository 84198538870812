.c-criss-cross {
  position: absolute;
  z-index: 20;

  &__top {
    left: 50%;
    top: 0;
    transform: translate3d(-50%, -42%, 0);
  }

  &__bottom {
    left: 50%;
    bottom: 0;
    transform: translate3d(-50%, 60%, 0);
  }

  .theme-shambles & {
    display: none;
  }
}
