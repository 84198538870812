.c-list,
%c-list {
  list-style-type: none;
  margin-bottom: $spacing-medium;
  padding: 0;
  position: relative;
  font-size: $font-small;

  @include breakpoint($bp-medium) {
    font-size: $base-font-size;
  }

  li {
    margin-bottom: $spacing-xsmall;
    padding-left: $spacing-medium;
    position: relative;

    &:before {
      color: palette(grey, light);
      display: inline-block;
      left: 0;
      line-height: 1;
      position: absolute;
      top: 0;
      vertical-align: middle;
    }

    a {
      color: inherit;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      &:hover {
        color: $c-white;
        border-bottom-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  // Unordered Lists
  &--unordered,
  %c-list--unordered {
    li:before {
      content: '.';
      font-size: toRems(35px);
      line-height: 10px;
      top: -0.07em;
    }
  }

  // Ordered Lists
  &--ordered,
  %c-list--ordered {
    counter-reset: section;

    li:before {
      content: counter(section, decimal) '.';
      counter-increment: section;
      font-weight: $font-weight-bold;
      left: 0;
      top: 0.275em;
    }

    ol {
      li {
        &:before {
          content: counter(section);
        }
      }
    }
  }
}
