.c-quote-block {
    background-color: $c-black;
    color: $c-white;
    display: block;
    padding: 25px 30px 38px 20px;
    margin: 0;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transition: all 700ms ease-in-out;
    
    @include breakpoint($bp-large) {
        padding: 25px 30px 25px 20px;
        max-width: 500px;
        display: inline-block;
        transform: scale(.9) translate3d(0,0,0);
        opacity: .4;
        background-color: rgba($c-black, .8);
        position: absolute;
        left: 40px;
        bottom: 40px;
        top: auto;
        width: auto;
        
        .is-selected & {
            transform: scale(1);   
            opacity: 1;
        }
    }      
    
    &--bottomLeft {
        
        @include breakpoint($bp-large) {
            top: auto;
            bottom: 40px;
        }      
        
    }
    
    &--bottomRight {
        
        @include breakpoint($bp-large) {
            top: auto;
            bottom: 40px;
            left: auto;
            right: 40px;
        }      
        
    }
    
    &--topRight {
        
        @include breakpoint($bp-large) {
            top: 40px;
            left: auto;
            bottom: auto;
            right: 40px;
        }      
        
    }
    
    p {
        @extend %t-georgia-mid-large;
        quotes: "\201C""\201D";
        margin-left: 2em;
        margin-right: 0;
        margin-bottom: .2em;
        font-style: italic;
        position: relative;
        line-height: 1.3;
        
        &:before {
            position: absolute;
            font-family: $georgia;
            color: palette(grey, x-light);
            opacity: .4;
            content: open-quote;
            font-size: 3.4em;
            left: -41px;
            top: 24px;
            line-height: 0.1em;
            vertical-align: -0.4em;
            padding-right: .2em;
        }
    }
    
    footer {
        font-style: italic;
        text-align: right;
        position: relative;
        display: inline-block;
        float: right;
        padding-left: 10px;
        
        &:before {
            content: "";
            display: block;
            height: 1px;
            background-color: $c-white;
            right: 100%;
            width: 60px;
            top: .86em;
            margin-left: -10px;
            position: absolute;
        }
    }
}