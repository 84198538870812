.o-half-split-special {
  @include breakpoint($bp-large) {
    &:before {
      content: '';
      display: block;
      width: 1px;
      left: 50%;
      position: absolute;
      height: 90%;
      top: 50%;
      transform: translateY(-50%);
      background-color: palette(grey);
    }
  }

  &__item {
    @include breakpoint($bp-large) {
      @include span-columns(6);
    }

    &:first-child {
      @include breakpoint($bp-large) {
        padding-right: $spacing-medium;
      }

      @include breakpoint($bp-large) {
        padding-right: 80px;
      }
    }

    &:last-child {
      @include breakpoint($bp-large) {
        padding-left: $spacing-medium;
      }

      @include breakpoint($bp-large) {
        padding-left: 80px;
      }
    }
  }
}
