.c-map-box { 
    background-color: $c-white;
    padding: $spacing-large $spacing-base;
    border-top: 1px solid palette(grey, x-light);
    border-bottom: 1px solid palette(grey, x-light);
    
    @include breakpoint($bp-medium) {
        border: 6px solid rgba(0,0,0,.5);    
        width: 400px;
        border-radius: $global-radius;
        padding: 40px;
        position: absolute;
        z-index: 100;
        left: $spacing-medium;
        top: $spacing-medium;
        
    }      

    .c-section-title {
        margin-bottom: $spacing-large;
    }
    
    &__address {
        @extend %t-lato-large-bold;
        border-bottom: 1px solid palette(grey, x-light);
        line-height: 1.3;
        padding-bottom: $spacing-large;
        margin-bottom: $spacing-large;
    }
    
    button {
        width: 100%;
    }
}