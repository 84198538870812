.c-radio {
	left: -9999em;
	position: absolute;
	visibility: hidden;

	+ label {
		cursor: pointer;
		font-size: $font-small;
		letter-spacing: 0;
		line-height: 2em;
		padding-left: 35px;
		padding-right: 20px;
		position: relative;
		text-transform: none;

		&:before {
			background: $c-white;
			border-radius: 20px;
			border: 1px solid palette(grey);
			content: '.';
			cursor: pointer;
			height: 20px;
			left: 0;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 200ms ease-in-out;
			width: 20px;
		}

		&:after {
			border-radius: 20px;
			content: '.';
			cursor: pointer;
			height: 10px;
			left: 5px;
			position: absolute;
			text-indent: -999em;
			top: 50%;
			transform: translateY(-50%);
			transition: background 200ms ease-in-out;
			width: 10px;
		}
	}

	&:checked + label:after {
		background: palette(grey, x-light);
	}
}
