.c-massive-quote-image {
  position: relative;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0 500px;

  .o-grid {
    position: relative;
    z-index: 2;
    max-width: 1100px;
  }

  &:before {
    background: linear-gradient(
      180deg,
      rgba(22, 22, 22, 0) 0.7%,
      #021219 19.27%,
      #021219 48.96%,
      rgba(2, 18, 25, 0.831633) 65.85%,
      rgba(2, 18, 25, 0) 100%
    );
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    height: 200px;
    top: -36px;
    display: block;
    z-index: 1;

    @include breakpoint($bp-large) {
      top: -95px;
      height: 500px;
    }
  }

  @include breakpoint($bp-medium) {
    padding: $spacing-large 0 600px;
  }

  @include breakpoint($bp-large) {
    padding: 120px 0 600px;
  }

  @include breakpoint($bp-xlarge) {
    padding: 120px 0 1000px;
    background-position: 50% 60%;
  }

  @include breakpoint(min-width 1700px) {
    padding: 120px 0 50%;
    background-position: 50% 60%;
  }
}
