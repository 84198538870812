.c-notice {
  background-color: palette(grey, xx-light);
  font-size: $font-small;
  text-align: center;
  padding: $spacing-small;

  @include breakpoint($bp-large) {
    padding: 60px 0;
    font-size: 18px;
  }

  &__heading {
    display: inline-block;
    font-size: $font-small;
    margin: 0 0.5rem 0 0;

    @include breakpoint($bp-large) {
      font-size: 18px;
    }
  }

  p {
    display: inline-block;
    margin: 0;
  }

  a:not(.c-btn) {
    border-bottom: 1px solid;
    color: $c-link;
    text-decoration: none;

    &:hover {
      color: $c-link-hover;
      border-bottom-color: transparent;
    }
  }

  .u-block {
    margin-top: $spacing-medium;
  }

  + .o-section {
    padding-bottom: $spacing-large;
    padding-top: $spacing-large;

    @include breakpoint($bp-large) {
      padding-bottom: $spacing-xlarge;
      padding-top: $spacing-xlarge;
    }
  }

  // &--bottom-spacing {
  //   margin-bottom: $spacing-large;
  // }

  &--large {
    p {
      font-size: $font-size-5;

      @include breakpoint($bp-medium) {
        font-size: $font-size-2;
      }

      b {
        font-weight: $font-weight-bold;
      }
    }

    @include breakpoint($bp-medium) {
      padding: $spacing-large 0;
    }
  }
}
