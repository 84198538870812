.c-quote {
  quotes: '\201C''\201D';
  margin-left: 0;
  margin-right: 0;
  padding: 0 $spacing-base;

  @include breakpoint($bp-medium) {
    padding: 0 $spacing-xlarge;
  }

  @include breakpoint($bp-large) {
    padding: 0 $spacing-xxlarge;
  }

  &:before {
    color: palette(grey, x-light);
    content: open-quote;
    font-size: 10em;
    opacity: 0.05;
    line-height: 0.1em;
    vertical-align: -0.4em;
    padding-right: 0.1em;
    position: absolute;
    left: $spacing-base;
    top: $spacing-large;

    @include breakpoint($bp-large) {
      left: 120px;
      top: 80px;
      font-size: 14em;
    }
  }

  p {
    @extend %t-georgia-mid-large;
    color: palette(grey, x-light);
    font-size: $base-font-size;
    font-style: italic;
    line-height: 1.55;

    @include breakpoint($bp-medium) {
      font-size: $font-size-4;
    }

    @include breakpoint($bp-large) {
      font-size: var(--quote-font-size);
    }

    &:not(:last-of-type) {
      margin-bottom: $spacing-medium;
    }
  }

  footer {
    margin-top: $spacing-medium;
    position: relative;
    font-family: $lato;
    color: palette(grey, x-light);
    text-align: right;

    @include breakpoint($bp-large) {
      font-size: $font-size-5;
    }

    span {
      display: inline-block;
      padding-left: $spacing-base;
      position: relative;

      &:before {
        display: block;
        height: 1px;
        content: '';
        background-color: palette(grey);
        width: 40px;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-1px);
      }
    }
  }
}
