.c-top-bar__nav-toggle {
  width: 44px;
  height: 45px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  position: relative;
  padding-top: 8px;
  right: 0;
  top: 2px;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: palette(grey, light);
    border-radius: 2px;
    opacity: 1;
    right: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 36px;

    &:nth-child(1) {
      top: 10px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 20px;
    }

    &:nth-child(4) {
      top: 30px;
    }
  }

  &.is-active {
    span {
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        right: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0%;
        right: 50%;
      }
    }
  }
}
