.u-1\/1 {
  @include span-columns(12);
}

.u-1\/2 {
  @include span-columns(6);
}

.u-1\/4 {
  @include span-columns(3);
}

.u-3\/4 {
  @include span-columns(9);
}

.u-1\/3 {
  @include span-columns(4);
}

.u-2\/3 {
  @include span-columns(4);
}

/**
 * @bp-medium
 */

.u-1\/2\@medium {
  @include breakpoint($bp-medium) {
    @include span-columns(6);
  }
}

.u-1\/4\@medium {
  @include breakpoint($bp-medium) {
    @include span-columns(3);
  }
}

.u-3\/4\@medium {
  @include breakpoint($bp-medium) {
    @include span-columns(9);
  }
}

.u-1\/3\@medium {
  @include breakpoint($bp-medium) {
    @include span-columns(4);
  }
}

.u-2\/3\@medium {
  @include breakpoint($bp-medium) {
    @include span-columns(4);
  }
}

/**
 * @bp-large
 */

.u-1\/2\@large {
  @include breakpoint($bp-large) {
    @include span-columns(6);
  }
}

.u-1\/4\@large {
  @include breakpoint($bp-large) {
    @include span-columns(3);
  }
}

.u-3\/4\@large {
  @include breakpoint($bp-large) {
    @include span-columns(9);
  }
}

.u-1\/3\@large {
  @include breakpoint($bp-large) {
    @include span-columns(4);
  }
}

.u-2\/3\@large {
  @include breakpoint($bp-large) {
    @include span-columns(4);
  }
}

.u-grid-last.u-grid-last {
  @include omega;
}

.u-1\/4-grid {
  display: grid;
  grid-gap: $spacing-xsmall;
  grid-template-columns: repeat(2, 1fr);

  @include breakpoint($bp-small) {
    grid-gap: $spacing-base;
  }

  @include breakpoint($bp-medium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint($bp-large) {
    grid-template-columns: repeat(4, 1fr);
  }

  &--stack-on-mobile {
    @include breakpoint($bp-xsmall) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.u-1\/5-grid {
  @extend .u-1\/4-grid;
  align-items: center;
  justify-items: center;

  @include breakpoint($bp-large) {
    grid-template-columns: repeat(5, 1fr);
  }

  img {
    mix-blend-mode: lighten;
  }
}
