// Overriding Formie variables
// https://github.com/verbb/formie/blob/craft-3/src/web/assets/frontend/src/scss/_variables.scss

.fui-submit {
  --fui-submit-btn-bg-color: #{palette(pink)};
  --fui-submit-btn-border-color: #dc3545;
  --fui-submit-btn-bg-color-hover: #{palette(pink, dark)};
  --fui-submit-btn-border-color-hover: #b02a37;
  --fui-submit-btn-box-shadow-focus: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
  --fui-border-radius: #{$global-radius};

  .theme-embers & {
    --fui-submit-btn-bg-color: transparent;
    --fui-submit-btn-border-color: #{$c-white};
    // --fui-submit-btn-border-width: 2px;
    --fui-btn-border: 2px solid #{$c-white};
    --fui-submit-btn-bg-color-hover: #{$c-white};
    --fui-submit-btn-color-hover: #0a1418; // This is blue dark not sure why it doesn't work from the pallete
    --fui-submit-btn-border-color-hover: #{$c-white};
    --fui-submit-btn-box-shadow-focus: 0 0 0 0.25rem rgba(235, 127, 69, 0.5);
  }
}

.fui-select {
  --fui-input-line-height: 1.85;
  --fui-input-font-size: #{$font-size-5};
  --fui-select-padding-right: 14px;
  --fui-select-bg-position: right 0.5rem center;
}

.fui-input {
  --fui-input-font-size: #{$font-size-5};
  --fui-input-padding: #{toEms(14px)};
}

.fui-field {
  &.inline-instructions {
    label {
      // width: 50%;
      // outline: 2px solid red;
      display: inline-block;
    }

    .fui-instructions {
      width: 50%;
      // display: inline;
      float: right;
      text-align: right;
      margin-bottom: 0;
      transform: #{translateY(4px)};
    }
  }
}

.fui-checkbox {
  margin-top: #{$spacing-small} !important;

  label {
    color: #{$c-white};
    font-size: #{toEms(15px)};
  }

  --fui-check-label-width: #{$spacing-base};
  --fui-check-label-height: #{$spacing-base};
  --fui-check-label-padding-left: 32px;
  --fui-check-label-top: 2px;
  --fui-check-check-bg-size: 10px auto;
}

.fui-instructions p {
  color: #{palette(grey, x-light)};
  opacity: 0.8;
}

.formie-recaptcha-placeholder {
  margin-bottom: #{$spacing-base};

  .grecaptcha-badge {
    box-shadow: none !important;
  }
}

.fui-alert-error {
  box-shadow: 0 0 0 2px #ab1a1a !important;
}

.fui-alert-success {
  box-shadow: 0 0 0 2px #309834 !important;
}

.flatpickr-calendar {
  font-family: #{$lato};
}

.fui-i {
  // Inherit from the site body, like the rest of the site
  --fui-font: #{$lato};

  // --fui-primary-color: #dc3545;
  // --fui-primary-color-hover: #bb2d3b;

  --fui-row-gutter: #{$spacing-base};

  // Borders
  // --fui-border-color: #CBD5E1;
  // --fui-border-radius: ;
  --fui-border-radius: 0px;

  // Input
  --fui-input-background-color: var(--form-input-bg-color);
  --fui-input-border: var(--form-input-border);
  --fui-input-placeholder-color: var(--form-input-placeholder-color);

  // Focus states
  // --fui-focus-border-color: #94A3B8;
  // --fui-focus-shadow: 0 0 0 2px palette(pink);

  --fui-btn-font-size: #{$font-size-5};
  --fui-btn-padding: 10px 30px;
  --fui-btn-border: 1px solid transparent;
  --fui-btn-font-weight: #{$font-weight-bold};

  // Field Labels
  --fui-label-font-size: #{toEms(18px)};
  --fui-label-line-height: 1.25;
  --fui-label-font-weight: 500;
  --fui-label-margin: #{$spacing-xsmall};

  // Field - Checkboxes/Radios
  --fui-check-font-size: #{toEms(14px)};
  --fui-check-label-bg-color: transparent;
  --fui-check-bg-color-checked: transparent;
  --fui-check-label-border: 2px solid #{$c-white};

  --fui-input-line-height: 1.2;

  // Validation
  --fui-error-font-size: #{$base-font-size};
  --fui-alert-font-size: #{$font-size-5};
  --fui-alert-margin-bottom: #{$spacing-medium};
  --fui-alert-line-height: 1.4;
  --fui-error: #ff8c8c;

  --fui-label-color: #{palette(grey, xx-light)};

  --fui-input-error-border-color: #ab1a1a;
  --fui-alert-error-color: #ab1a1a;

  --fui-alert-success-color: #29802c;
}
