.o-page-nav {
    text-align: center;
    
    li {
        margin-bottom: 5px;
        
        @include breakpoint($bp-medium) {
            display: inline-block;
            margin: 5px;
        }      
    }
}