.o-section {
  @include row;
  padding: 60px 0;

  &--bottom-border {
    position: relative;

    &:after {
      background-color: palette(grey, x-light);
      display: block;
      height: 1px;
      width: 60%;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      bottom: 0;
    }
  }

  @include breakpoint($bp-medium) {
    padding: $spacing-large 0;
  }

  @include breakpoint($bp-large) {
    padding: var(--section-padding-desktop);
  }

  &--no-padding-top {
    padding-top: 0;
  }

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--more-bottom-padding\@small {
    padding-bottom: 60px !important;
  }

  &--shallow {
    @include breakpoint($bp-large) {
      padding: 90px 0;
    }
  }
}
