.c-simple-item {
    text-align: center;
    
    &__title {
        @extend %t-lato-small-heavy;
        color: palette(grey, mid-light);
        
        // .is-selected & {
        //     color: palette(pink);
        // }
    }
    
    &__content {
        @extend %t-merriweather-large-light;
        font-size: $font-size-3;
        margin-bottom: 0;
        
    }
}