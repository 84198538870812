/**
 * Centered images in a row of 5
 */

.o-image-slideshow {
  margin-bottom: 25px;

  &__item {
    width: 100%;
    transition: opacity 600ms ease-in-out;

    @include breakpoint($bp-medium) {
      height: 220px;
    }

    &.is-selected {
      opacity: 1;
    }

    @include breakpoint($bp-small) {
      width: 50%;
      padding: 10px;
    }

    @include breakpoint($bp-medium) {
      width: 20%;
    }
  }

  &--alt-arrows {
    .flickity-prev-next-button {
      height: 54px;
      width: 54px;
      background-color: transparent;

      svg path {
        fill: $c-white;
      }
    }
  }
}
