.o-menu-cols {
  margin-top: $spacing-base;

  @include breakpoint($bp-medium) {
    column-count: 2;
    column-gap: 60px;
  }

  &__col {
    display: inline-block;
  }
}
