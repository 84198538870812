.c-card {
    border: 1px solid palette(grey, x-light);
    box-shadow: 0 4px 15px rgba(100,100,100,.1);
    transition: all 300ms ease-in-out;
    
    &:hover {
        box-shadow: 0 4px 20px rgba(100,100,100,.2);
        
        h2 {
            color: $c-link;
        }
    }
    
    &__content {
        padding: $spacing-medium;
        
        h2 {
            font-size: 20px;
        }
        
        p {
            color: palette(grey, light);
        }
    }
    
    a {
        color: currentColor;
    }
}