.c-intro {
  font-family: $heading-font-family;
  font-weight: $font-weight-light;
  color: #525252;
  padding: 60px 0;
  font-size: $font-size-4;

  p {
    margin-bottom: 0;
  }

  @include breakpoint($bp-medium) {
    font-size: 20px;
    padding: $spacing-large 0;
  }

  @include breakpoint($bp-large) {
    font-size: 26px;
    padding: 120px 0;
  }

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--article-header {
    padding: 60px 0 30px;

    @include breakpoint($bp-large) {
      padding-top: 100px;
      padding-bottom: 30px;
    }
  }
}
