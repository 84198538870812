.o-grid-gallery {
  display: grid;
  grid-gap: $spacing-medium;
  // 4x columns in css grid

  @include breakpoint($bp-medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint($bp-large) {
    grid-template-columns: repeat(4, 1fr);
  }
}
