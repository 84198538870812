.c-menu-list {
  li {
    margin-bottom: $spacing-large;

    // @include breakpoint($bp-large) {
    //     display: inline-block;
    //     margin-bottom: $spacing-large;
    //
    //     &:nth-child(odd) {
    //         padding-right: $spacing-large;
    //     }
    //
    //     &:nth-child(even) {
    //         padding-left: $spacing-large;
    //     }
    // }
  }

  &__subtitle {
    font-size: toRems(20px);
  }

  h4 {
    @extend %t-lato-mid-heavy;
    margin-bottom: 0;
  }
}
