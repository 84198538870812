/**
 * Generated Icons
 *
 * DO NOT EDIT THIS FILE IT IS FOR REFERENCE ONLY
 *
 * This icons Sass file is generated by Gulp
 * To add more icons to the icon font:
 *    1. Add svgs to "images/icons/"
 *    2. Run `gulp icons` in your terminal
 *
 */

@font-face {
	font-family: "fed-base";
	src: url("/fonts/fed-base.eot");
	src: url("/fonts/fed-base.eot?#iefix") format("embedded-opentype"),
		url("/fonts/fed-base.woff") format("woff"),
		url("/fonts/fed-base.ttf") format("truetype"),
		url("/fonts/fed-base.svg#fed-base") format("svg");
	font-style:  normal;
    font-weight:  normal;
}

%font-icon,
[class^="i-"],
[class*=" i-"] {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: "fed-base";
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-decoration: none;
	text-transform: none;
}

$icons: 'arrow-left', 'arrow-right', 'chevron-down', 'chevron-left', 'chevron-right', 'chevron-up', 'cross', 'google-plus', 'instagram', 'pinterest', 'search', 'share', 'tick', 'twitter', 'vimeo', 'youtube';

.i-arrow-left:before,
%i-arrow-left {
	@extend %font-icon;
	content: "\E001";
}

.i-arrow-right:before,
%i-arrow-right {
	@extend %font-icon;
	content: "\E002";
}

.i-chevron-down:before,
%i-chevron-down {
	@extend %font-icon;
	content: "\E003";
}

.i-chevron-left:before,
%i-chevron-left {
	@extend %font-icon;
	content: "\E004";
}

.i-chevron-right:before,
%i-chevron-right {
	@extend %font-icon;
	content: "\E005";
}

.i-chevron-up:before,
%i-chevron-up {
	@extend %font-icon;
	content: "\E006";
}

.i-cross:before,
%i-cross {
	@extend %font-icon;
	content: "\E007";
}

.i-google-plus:before,
%i-google-plus {
	@extend %font-icon;
	content: "\E008";
}

.i-instagram:before,
%i-instagram {
	@extend %font-icon;
	content: "\E009";
}

.i-pinterest:before,
%i-pinterest {
	@extend %font-icon;
	content: "\E00A";
}

.i-search:before,
%i-search {
	@extend %font-icon;
	content: "\E00B";
}

.i-share:before,
%i-share {
	@extend %font-icon;
	content: "\E00C";
}

.i-tick:before,
%i-tick {
	@extend %font-icon;
	content: "\E00D";
}

.i-twitter:before,
%i-twitter {
	@extend %font-icon;
	content: "\E00E";
}

.i-vimeo:before,
%i-vimeo {
	@extend %font-icon;
	content: "\E00F";
}

.i-youtube:before,
%i-youtube {
	@extend %font-icon;
	content: "\E010";
}

