.u-bg-grey-xxlight {
  background-color: palette(grey, xx-light);
}

.u-bg-blue-grey-dark {
  background-color: #323337;
  color: $c-white;

  a {
    color: $c-white;
    text-decoration: underline;
  }
}

.u-bg-pink {
  background-color: palette(pink);
  color: $c-white;

  .c-btn {
    background-color: palette(pink, dark);
  }
}

.u-bg-dark-grey {
  background-color: palette(grey, dark);
  color: $c-white;

  a {
    color: $c-white;
    text-decoration: underline;
  }
}

.u-bg-x-dark-grey {
  background-color: palette(grey, x-dark);
  color: palette(grey, x-light);

  a {
    color: palette(grey, x-light);
    text-decoration: underline;
  }
}

.u-bg-xx-dark-grey {
  background-color: palette(grey, xx-dark);
  color: palette(grey, x-light);

  a {
    color: palette(grey, x-light);
    text-decoration: underline;
  }
}
