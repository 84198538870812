.s-cms-content {
  @include clearfix;

  @include breakpoint($bp-large) {
    font-size: $font-size-5;
  }

  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
  }

  a {
    border-bottom: 1px solid;
    color: $c-link;
    text-decoration: none;

    &:hover {
      color: $c-link-hover;
      border-bottom-color: transparent;
    }

    &:visited,
    &:active {
      color: $c-link-visited;
      border-bottom: 1px solid;
    }
  }

  // Images

  // Image Captions
  figcaption {
    background: #222;
    padding: $spacing-base;
    color: $c-white;
    font-size: toRems(15px);
  }

  img {
    float: left;
    margin: 0 $spacing-medium $spacing-medium 0;
    max-width: 300px;
  }

  figure {
    margin-bottom: $spacing-medium;

    img {
      float: none;
      margin-bottom: 0;
      width: 100%;
      max-width: 100%;
    }
  }

  blockquote {
  }

  cite {
  }

  a {
    word-break: break-word;
  }

  p {
    margin-bottom: $spacing-medium;
    margin-top: 0;

    &:last-of-type:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    @extend %c-list;
    @extend %c-list--unordered;
  }

  ol {
    @extend %c-list;
    @extend %c-list--ordered;
  }
}
