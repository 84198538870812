.c-divider {
  background-color: palette(grey, dark);
  display: block;
  height: 1px;
  margin: $spacing-medium auto;
  width: 60%;

  @include breakpoint($bp-medium) {
    margin: $spacing-large auto;
  }

  @include breakpoint($bp-large) {
    margin: 120px auto;
  }

  &--smaller-vertical-margin {
    @include breakpoint($bp-large) {
      margin: $spacing-large auto;
    }
  }
}
