.c-top-bar {
  background-color: var(--top-bar-bg-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: var(--top-bar-padding);
  position: fixed;
  top: 0;
  transition: background-color 500ms ease-in-out, padding-top 500ms ease-in-out;
  width: 100%;
  z-index: 200;

  @include breakpoint($bp-large) {
    box-shadow: none;
    background-color: transparent;
    padding: var(--top-bar-padding-desktop);
    padding-top: 40px;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);

    &.has-bg-colour {
      background-color: var(--top-bar-bg-color-desktop);
      padding-top: 24px;
      text-shadow: none;

      .c-top-bar__logo-small {
        opacity: 0;
        transform: scale(0.9) translateY(-20px);
      }

      .c-top-bar__logo {
        opacity: 1;
      }

      .c-top-bar__address {
        margin-left: 56px;
      }

      .c-nav ul {
        margin-left: auto;
        padding-left: $spacing-large;
        // text-align: right;
      }
    }
  }

  .o-grid {
    @include breakpoint($bp-large) {
      display: flex;
      align-items: center;
    }
  }

  &__logo {
    position: relative;
    top: var(--top-bar-logo-top);
    left: -8px;

    svg {
      width: 38px;
      height: 38px;
      fill: $c-black;

      @include breakpoint($bp-large) {
        fill: $c-white;
      }
    }

    @include breakpoint($bp-large) {
      left: 50px;
      opacity: 0;
      position: absolute;
      top: -7px;
    }
  }

  &__logo-small {
    height: 60px;
    display: none;
    position: absolute;
    left: $spacing-large;
    transform: scale(1) translateY(0);

    @include breakpoint($bp-large) {
      display: inline-block;
    }

    svg {
      height: 100%;
    }
  }

  &__address {
    color: $c-white;
    display: none;
    transition: margin 300ms ease-in-out;
    margin-bottom: 0;

    a {
      color: $c-white;
    }

    @include breakpoint($bp-large) {
      display: block;
      float: left;
    }
  }

  &__nav-toggle {
    float: right;

    @include breakpoint($bp-large) {
      display: none;
    }
  }

  &--inner-page {
    .c-top-bar__address {
      opacity: 0;
    }

    &.has-bg-colour {
      .c-top-bar__address {
        opacity: 1;
      }
    }
  }

  &--centered {
    .c-top-bar__address {
      display: none;
    }
  }

  &--alt {
    .c-top-bar__address {
      display: none;
    }

    .c-top-bar__logo-small {
      width: 34px;
      height: 50px;

      svg {
        width: 100%;
      }
    }
  }
}
