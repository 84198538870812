.c-subscribe {
    position: relative;
    
    .is-open {
        display: block;
    }
    
    .is-hidden {
        transform: scale(.8);
        width: 100%;
        transition: all 400ms ease-in-out;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        
        @include breakpoint($bp-medium) {
            position: relative;
        }      
    }
    
    &__form {
        position: absolute;
        top: 0;
        transform: translateY(-25%) scale(.8);
        width: 100%;
        transition: all 400ms ease-in-out;
        opacity: 0;
        visibility: hidden;        
        
        &.is-open {
            display: block;
            transform: translateY(5%) scale(1);
            opacity: 1;
            visibility: visible;
            position: relative;
            
            @include breakpoint($bp-medium) {
                transform: translateY(-25%) scale(1);
                position: absolute;
            }  
        }
    }
    
}