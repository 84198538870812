// Site
$max-site-width: 1440px;

// Font families
$lato: 'Lato', 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
  Helvetica, Arial, 'Lucida Grande', sans-serif;
$merriweather: 'Merriweather', Georgia, serif;
$georgia: Georgia, serif;

// Font sizes
$font-size-1: toRems(50px);
$font-size-2: toRems(28px);
$font-size-3: toRems(24px);
$font-size-4: toRems(22px);
$font-size-5: toRems(18px);

// Small font sizes
$font-small: toRems(14px);
$font-xsmall: toRems(13px);
$font-xxsmall: toRems(12px);

// Font weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

// Base Variables
$base-font-family: $lato;
$heading-font-family: $merriweather;
$base-font-size: 16px;
$base-font-weight: 400;
$base-line-height: 1.6;

// Neat grid settings
$grid-columns: 12;
$max-width: 1240px;
$visual-grid: false !default;
$border-box-sizing: true !default;
$disable-warnings: false !default;
$column: 2em;
$gutter: 1em;

// Have to import Neat after grid settings
@import 'neat',
  // Neat - http://neat.bourbon.io/
  'node_modules/breakpoint-sass/stylesheets/breakpoint.scss'; // Breakpoints - http://breakpoint-sass.com/

// Breakpoints
$bp-xlarge: min-width 1200px;
$bp-large: min-width 992px;
$bp-large-max: max-width 992px;
$bp-medium: min-width 768px;
$bp-medium-max: max-width 768px;
$bp-small: min-width 540px;
$bp-xsmall: max-width 540px;

// Z-indexes
$z-header: 1000;
$z-modal: 2000;

// Global variables
$global-radius: 4px;
$global-easing: cubic-bezier(0.165, 0.84, 0.44, 1);
$top-bar-height: 65px;

// Global spacing
$spacing-xxsmall: toRems(5px);
$spacing-xsmall: toRems(10px);
$spacing-small: toRems(15px);
$spacing-base: toRems(20px);
$spacing-medium: toRems(30px);
$spacing-large: toRems(50px);
$spacing-xlarge: toRems(100px);
$spacing-xxlarge: toRems(140px);

// Transitions
$trans-ease-out-back: cubic-bezier(0.17, 0.89, 0.33, 1.29);
$trans-ease-out-quartic: cubic-bezier(0.165, 0.84, 0.44, 1);
