.c-arrow-top {
    &:before {
        @include triangle(40px, $c-white, down);
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 50;
        margin-left: -10px;
    }
}