.c-slideshow {
  max-height: 300px;
  // opacity: 0;
  // overflow: hidden;
  position: relative;
  transition: opacity 0.87s $global-easing;

  @include breakpoint($bp-medium) {
    max-height: 480px;
  }

  &__item {
    transition: opacity 0.5s $global-easing, transform 0.87s $global-easing;
    width: 100%;

    &.is-selected {
    }
  }

  &:hover {
    .flickity-prev-next-button {
      opacity: 1;
      transform: translateX(0) translateY(-50%) !important;
    }
  }

  .js & {
    &.flickity-enabled {
      // max-height: none;
      // opacity: 1;
    }
  }

  .flickity-prev-next-button {
    display: none;
    background-color: transparent;
    opacity: 0;
    transition: all 0.2s $global-easing;
    height: 100%;

    .touch & {
      display: none;
    }

    &:focus {
      box-shadow: none;
    }

    @include breakpoint($bp-medium) {
      display: block;
    }

    path {
      fill: $c-white;
    }

    &.previous {
      transform: translateX(-20px) translateY(-50%);
    }

    &.next {
      transform: translateX(20px) translateY(-50%);
    }
  }

  .flickity-page-dots {
    bottom: $spacing-base;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: auto;

    .dot {
      background-color: rgba($c-black, 0.2);
      // border: 2px solid transparent;
      height: 8px;
      margin: 0 5px;
      opacity: 1;
      position: relative;
      top: -1px;
      transition: border 0.5s $global-easing,
        background-color 0.5s $global-easing;
      width: 8px;

      &.is-selected {
        background-color: palette(pink);
        // border-color: palette(pink);
        // height: 10px;
        // top: 0;
        // width: 10px;
      }
    }

    @include breakpoint($bp-medium) {
      bottom: $spacing-medium;
    }
  }

  // &--quote {
  //     background-color: palette(grey, light);
  //     padding: $spacing-medium;
  //
  //     @include breakpoint($bp-medium) {
  //         padding: toEms(80px) $spacing-medium $spacing-medium;
  //     }
  //
  //     @include breakpoint($bp-xsmall) {
  //         padding: $spacing-medium $spacing-base;
  //     }
  //
  //     .c-slideshow__item {
  //         opacity: 0;
  //         transform: scale(0.75);
  //         transition: opacity 0.5s $global-easing, transform 1s $global-easing;
  //
  //         &.is-selected {
  //             opacity: 1;
  //             transform: scale(1);
  //         }
  //     }
  //
  //     .flickity-prev-next-button {
  //         path {
  //             fill: $c-font;
  //         }
  //     }
  //
  //     .flickity-page-dots {
  //         position: static;
  //         transform: none;
  //         margin-top: $spacing-medium;
  //
  //         @include breakpoint($bp-medium) {
  //             margin-top: $spacing-large;
  //         }
  //
  //         .dot {
  //             background-color: rgba($c-black, 0.2);
  //
  //             &.is-selected {
  //                 // border-color: palette(blue, light);
  //             }
  //         }
  //     }
  // }

  &.has-one-slide {
    .flickity-page-dots,
    .flickity-prev-next-button {
      display: none;
    }
  }
}
