.c-map {
    height: 400px;
    width: 100%;
    margin-bottom: $spacing-medium;
    display: none;

    @include breakpoint($bp-medium) {
        height: 680px;
        margin-bottom: 0;
        display: block;
        margin-bottom: 10px;
    }

    &__info {
        min-width: 200px;
        padding: $spacing-xsmall;

        h2 {
            font-size: toRems(16px) !important;
            margin-bottom: .4em;
        }

        dl {
            font-size: toRems(13px);
            margin: 0;
            padding: 0;

            dt {
                // font-weight: $font-weight-medium;
                margin: 0;
                padding: 0;
            }

            dd {
                margin: 0 0 .5em 0;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }
            }

        }

    }
}
