// Left floating image
.u-img-1\/2-left.u-img-1\/2-left {
  @include span-columns(6);
  float: left;
  margin-bottom: $spacing-base;
}

.u-img-1\/3-left.u-img-1\/3-left {
  @include span-columns(4);
  float: left;
  margin-bottom: $spacing-base;
}

.u-img-1\/4-left.u-img-1\/4-left {
  @include span-columns(3);
  float: left;
  margin-bottom: $spacing-base;
}

// Right floating image
@include direction-context(right-to-left) {
  .u-img-1\/2-right.u-img-1\/2-right {
    @include span-columns(6);
    float: right;
    margin-bottom: $spacing-base;
  }

  .u-img-1\/3-right.u-img-1\/3-right {
    @include span-columns(4);
    float: right;
    margin-bottom: $spacing-base;
  }

  .u-img-1\/4-right.u-img-1\/4-right {
    @include span-columns(3);
    float: right;
    margin-bottom: $spacing-base;
  }
}

.u-rounded {
  border-radius: 4px;
}
