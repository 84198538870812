.o-split {
  @include breakpoint($bp-large) {
    display: grid;
    grid-template-rows: none;
    align-items: center;
    grid-template-columns: 1.1fr 1fr;
    grid-gap: $spacing-xlarge;
  }

  &__content {
    @include breakpoint($bp-large-max) {
      margin-bottom: $spacing-large;
    }

    p {
      @include breakpoint($bp-large) {
        font-size: $font-size-5;
      }
    }

    .c-btn {
      margin-top: $spacing-medium;
    }
  }

  &__media {
    @include breakpoint($bp-large-max) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    .o-slideshow--dots-right .flickity-page-dots {
      @include breakpoint($bp-large) {
        align-items: center;
        justify-content: flex-end;
        right: 0;
      }
    }

    .o-slideshow {
      margin-bottom: $spacing-large;

      @include breakpoint($bp-medium-max) {
        margin-left: -$spacing-base;
        margin-right: -$spacing-base;
      }
    }
  }

  &--reversed {
    @include breakpoint($bp-large) {
      grid-template-columns: 1fr 1.1fr;
    }

    .o-split__content {
      @include breakpoint($bp-large) {
        order: 2;
      }
    }
  }

  &--single-image {
    .o-split__media {
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        @include breakpoint($bp-large) {
          max-width: 90%;
        }
      }
    }
  }
}
