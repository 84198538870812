.o-grid,
%o-grid {
  @include outer-container;
  position: relative;
  padding: 0 $spacing-base;

  @include breakpoint($bp-small) {
    padding: 0 $spacing-medium;
  }

  @include breakpoint($bp-medium) {
    padding: 0 $spacing-large;
  }

  &--no-pad {
    padding: 0;
  }

  &--medium {
    max-width: 1140px;
  }

  &--slim {
    max-width: 940px;
  }

  &--skinny {
    max-width: 600px;
  }
}
