.o-page-container {
  @include clearfix;
  background-color: var(--page-container-bg-color);
  margin: 0 auto;
  max-width: $max-site-width;
  min-width: 320px;
  // padding-top: $top-bar-height;
  position: relative;
  transition: all 0.3s $global-easing;
  width: 100%;

  @include breakpoint($bp-large) {
    padding: $spacing-xsmall;
  }

  .c-intro + .s-content-blocks .o-section:first-child {
    padding-top: $spacing-large;
  }

  .c-main {
    min-height: 300px;

    @include breakpoint($bp-large) {
      min-height: 500px;
    }
  }

  // &--has-gradient {
  //   &:before {
  //     background: linear-gradient(
  //       to bottom,
  //       var(--page-container-bg-color) 0%,
  //       var(--page-container-bg-color) 50%,
  //       var(--page-container-bg-color) 100%
  //     );
  //   }
  //   content: '';
  //   height: 100%;
  //   left: 0;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  //   z-index: -1;
  // }
}
