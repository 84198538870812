.c-form {
  fieldset {
    border: none;
    padding: 0;
  }

  ol {
    li {
      margin-bottom: $spacing-small;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__btn-note {
    color: palette(grey, light);
    display: block;
    font-style: italic;
    margin-top: $spacing-xsmall;
  }

  &--centred {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}

button {
  background: none;
  border: none;
  padding: 0;
}

label {
  display: block;
  font-size: $font-xxsmall;
  font-weight: 500;
  margin-bottom: $spacing-xsmall - toRems(1px);
  padding-left: 2px;
}

input[type='text'],
input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
textarea {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-input-bg-color);
  border: var(--form-input-border);
  color: var(--form-input-color);
  font-size: $font-size-5;
  padding: 14px;
  width: 100%;
  border-radius: $global-radius;

  &::-webkit-input-placeholder {
    color: var(--form-input-placeholder-color);
  }

  &:-moz-placeholder {
    color: var(--form-input-placeholder-color);
  }

  &::-moz-placeholder {
    color: var(--form-input-placeholder-color);
  }

  &:-ms-input-placeholder {
    color: var(--form-input-placeholder-color);
  }

  // .parsley-error & {
  //   border-color: palette(errors) !important;
  // }

  &:focus {
    outline: none;
  }
}

textarea {
  -webkit-appearance: none;
  // resize: none;
  min-height: 150px;

  // &.parsley-error {
  //   &::-webkit-input-placeholder {
  //     color: palette(errors);
  //   }

  //   &:-moz-placeholder {
  //     color: palette(errors);
  //   }

  //   &::-moz-placeholder {
  //     color: palette(errors);
  //   }

  //   &:-ms-input-placeholder {
  //     color: palette(errors);
  //   }
  // }

  &:focus {
    outline: none;
  }
}
