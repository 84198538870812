.o-cards {
    li {
        margin-bottom: 5%;
        
        @include breakpoint($bp-small) {
            @include span-columns(6);
            margin-bottom: 2.85714%;
            
            &:nth-child(2n) {
                 margin-right: 0;
            }
        }      
        
        @include breakpoint($bp-large) {
            @include span-columns(4);
            
            &:nth-child(2n) {
                 margin-right: 2.85714%;
            }
            
            &:nth-child(3n) {
                 margin-right: 0;
            }
        }      
    } 
}