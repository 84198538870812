.theme-shambles {
  --body-bg-color: #f3f3f3;
  --button-bg-color: #ef465a;
  --button-hover-bg-color: #c82e40;
  --button-hover-color: #{$c-white};
  --button-color: #{$c-white};
  --button-border: none;
  --p-margin-bottom: #{$spacing-small};
  --top-bar-padding: 8px 0;
  --top-bar-padding-desktop: 8px 0 24px;
  --top-bar-bg-color: #{$c-white};
  --top-bar-bg-color-desktop: #{palette(grey, x-dark)};
  --top-bar-logo-top: 3px;
  --hero-padding-top: $spacing-medium;
  --hero-padding-top-desktop: 160px;
  --nav-link-color-mobile: #{$c-black};
  --nav-list-item-border-bottom-mobile: none;
  --nav-text-transform: uppercase;
  --nav-font-weight: #{$font-weight-bold};
  --page-container-bg-color: #{$c-white};
  --section-title-line-color: #ef465a;
  --section-title-font-size: #{$font-size-5};
  --section-title-font-size-desktop: #{$font-size-5};
  --section-title-margin-bottom-desktop: 4em;
  --section-padding-desktop: 120px 0;
  --section-title-font-size-small: 24px;
  --section-title-font-weight: #{$font-weight-black};
  --quote-font-size: 20px;
  --site-info-font-family: #{$lato};
  --flickity-dots-color: #{palette(grey, x-light)};
  --flickity-dots-selected-color: #{palette(pink)};
  --full-page-image-content-bg-color: #{rgba(palette(grey, x-dark), 0.8)};
  --cta-bg-color: #{palette(grey, xx-light)};
  --cta-padding: #{$spacing-medium} 0;
  --cta-padding-large: 75px #{$spacing-large};
  --form-input-border: 1px solid #{palette(grey, x-light)};
  --form-input-color: $c-font;
  --form-input-placeholder-color: #{palette(grey, light)};
  --form-input-bg-color: #{$c-white};
  --fui-primary-color: #dc3545;
  --fui-primary-color-hover: #bb2d3b;
  --fui-focus-shadow: 0 0 0 2px #{palette(pink)};
  --fui-input-border-radius: #{$global-radius};
}

.c-text-listing {
  p {
    @extend %t-lato-large-bold;
  }
}
