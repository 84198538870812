.c-cta {
  background-color: var(--cta-bg-color);
  text-align: center;
  padding: var(--cta-padding);
  width: 100%;

  @include breakpoint($bp-medium) {
    display: table;
    padding: var(--cta-padding-large);
  }

  &__heading {
    font-family: $base-font-family !important;
    text-transform: none !important;

    @include breakpoint($bp-medium) {
      font-size: 26px !important; // @TODO: Check this font size
    }
  }

  &__subheading {
    @include breakpoint($bp-large) {
      font-size: $font-size-4;
    }

    p {
      @include breakpoint($bp-medium) {
        margin-bottom: 0;
      }
    }
  }

  .t-merriweather-mid {
    opacity: 0.8;
  }

  header {
    @include breakpoint($bp-medium) {
      display: table-cell;
      text-align: left;
      vertical-align: middle;
      width: 60%;
    }
  }

  footer {
    @include breakpoint($bp-medium) {
      display: table-cell;
      text-align: right;
      vertical-align: middle;
      width: 40%;
    }

    .c-cta__actions {
      @include breakpoint($bp-medium) {
        float: right;
        text-align: center;
        min-width: 320px;
      }
    }

    small {
      display: block;
      font-size: $font-small;
      font-style: italic;
      margin-top: 0.5em;
      opacity: 0.8;
    }
  }

  &--wrapped {
    max-width: 1346px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint($bp-medium) {
      border-radius: 6px;
    }
  }
}
