.c-btn {
  @extend %t-lato-mid;
  background-color: var(--button-bg-color);
  border: var(--button-border);
  border-radius: $global-radius;
  color: var(--button-color);
  display: inline-block;
  padding: 8px 30px;
  text-align: center;
  width: 100%;
  transition: all 300ms ease-in-out;
  overflow: hidden;

  &:hover {
    color: var(--button-hover-color);
    background-color: var(--button-hover-bg-color);

    .c-btn__text {
      transform: translateY(-180%);

      &:before {
        opacity: 1;
      }
    }
  }

  @include breakpoint($bp-medium) {
    width: auto;
  }

  @include breakpoint($bp-large) {
    padding: 8px 40px;
  }

  &__text {
    transition: transform 0.4s $trans-ease-out-quartic;
    position: relative;
    display: block;

    &:before {
      content: attr(data-hover-text);
      position: absolute;
      top: 180%;
      transform: translateZ(0);
    }
  }
}
