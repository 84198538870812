.u-spacing-top-zero.u-spacing-top-zero {
    margin-top: 0;
}

.u-spacing-bottom-zero.u-spacing-bottom-zero {
    margin-bottom: 0;
}

.u-spacing-top-small.u-spacing-top-small {
    margin-top: $spacing-small;
}

.u-spacing-bottom-small.u-spacing-bottom-small {
    margin-bottom: $spacing-small;
}

.u-spacing-top-base.u-spacing-top-base {
    margin-top: $spacing-base;
}

.u-spacing-bottom-base.u-spacing-bottom-base {
    margin-bottom: $spacing-base;
}

.u-spacing-top-medium.u-spacing-top-medium {
    margin-top: $spacing-medium;
}

.u-spacing-bottom-medium.u-spacing-bottom-medium {
    margin-bottom: $spacing-medium;
}

.u-spacing-bottom-large.u-spacing-bottom-large {
    margin-bottom: $spacing-large;
}

.u-spacing-top-large.u-spacing-top-large {
    margin-top: $spacing-large;
}

/**
 * @bp-medium
 */

.u-spacing-top-zero\@medium.u-spacing-top-zero\@medium {
    @include breakpoint($bp-medium) {
        margin-top: 0;
    }
}

.u-spacing-bottom-zero\@medium.u-spacing-bottom-zero\@medium {
    @include breakpoint($bp-medium) {
        margin-bottom: 0;
    }
}

.u-spacing-top-small\@medium.u-spacing-top-small\@medium {
    @include breakpoint($bp-medium) {
        margin-top: $spacing-small;
    }
}

.u-spacing-bottom-small\@medium.u-spacing-bottom-small\@medium {
    @include breakpoint($bp-medium) {
        margin-bottom: $spacing-small;
    }
}

.u-spacing-top-base\@medium.u-spacing-top-base\@medium {
    @include breakpoint($bp-medium) {
        margin-top: $spacing-base;
    }
}

.u-spacing-bottom-base\@medium.u-spacing-bottom-base\@medium {
    @include breakpoint($bp-medium) {
        margin-bottom: $spacing-base;
    }
}

.u-spacing-top-medium\@medium.u-spacing-top-medium\@medium {
    @include breakpoint($bp-medium) {
        margin-top: $spacing-medium;
    }
}

.u-spacing-bottom-medium\@medium.u-spacing-bottom-medium\@medium {
    @include breakpoint($bp-medium) {
        margin-bottom: $spacing-medium;
    }
}

.u-spacing-bottom-large\@medium.u-spacing-bottom-large\@medium {
    @include breakpoint($bp-medium) {
        margin-bottom: $spacing-large;
    }
}
