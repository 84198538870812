.c-caption {
    @extend %t-lato-mid-italic;
    padding: $spacing-medium;
    color: palette(grey, light);
    
    @include breakpoint($bp-medium) {
        padding: $spacing-large $spacing-large $spacing-medium $spacing-large;
    }  
    
    p {
        @include breakpoint($bp-large) {
            width: 60%;
        }      
    }
}