.c-subscribe-form-simple {
    li {
        @include breakpoint($bp-large) {
            display: inline-block;
        }  
        
        &:first-child {
            @include breakpoint($bp-large) {
                margin-right: $spacing-xsmall;
                margin-top: $spacing-medium;
            }      
        }    
        
        
        input {
            width: 100%;
            @include breakpoint($bp-large) {
                min-width: 400px;
            }      
        }
        
        small {
            
            @include breakpoint($bp-large) {
                position: absolute;
                transform: translateX(-50%) translateY(10px);
                opacity: .6;
            }      
        }
    }
}