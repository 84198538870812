.c-image-section {
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    color: $c-white;
    text-shadow: 1px 1px 3px rgba(0,0,0,.8);
    transition: all 400ms ease-in-out;
    
    @include breakpoint($bp-large) {
        padding-top: 20px;
    }
    
    &:before {
        background-color: rgba(0,0,0,.6);
        bottom: 0;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        
        @include breakpoint($bp-large) {
            background-color: rgba(0,0,0,.6);
        }
    }
    
    &__inner {
        position: relative;
    }
    
    &__content {
        @extend %t-merriweather-mid-large-light;
        font-size: $font-size-5;
        text-shadow: 1px 1px 3px rgba(0,0,0,.8);
        margin: $spacing-medium 0;
        
        @include breakpoint($bp-medium) {
            font-size: $font-size-4;
        }      
        
        @include breakpoint($bp-large) {
            font-size: $font-size-2;
            margin: 100px 0;
            max-width: 70%;
        }      
    }
    
    .c-section-title {
        @include breakpoint($bp-large) {
            font-size: $font-size-4;    
        }      
        
    }
}