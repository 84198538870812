.c-checkbox {
  left: -9999em;
  position: absolute;
  visibility: hidden;

  + label {
    color: $c-font;
    cursor: pointer;
    font-size: toEms(14px);
    font-weight: normal;
    letter-spacing: 0;
    letter-spacing: 0;
    line-height: 1.6em;
    padding-left: 32px;
    position: relative;
    text-transform: none;

    small {
      display: block;
      line-height: 1.5;
      font-size: 90%;
    }

    &:before {
      background: $c-white;
      border-radius: 3px;
      border: 1px solid palette(grey, mid-dark);
      content: '.';
      cursor: pointer;
      height: 20px;
      left: 0;
      transform: translateY(-50%);
      position: absolute;
      text-indent: -999em;
      top: 1.05em;
      transition: background 200ms ease-in-out;
      width: 20px;
    }

    &:after {
      transition: background-color 0.2s ease-in-out;
      border-radius: 2px;
      content: '';
      background-color: $c-white;
      display: block;
      width: 12px;
      height: 12px;
      left: 4px;
      position: absolute;
      top: 1.05em;
      transform: translateY(-50%);
    }
  }

  &:checked {
    & + label {
      &:before {
        // background-color: palette(grey, x-light);
      }
      &:after {
        background-color: palette(pink);
      }
    }
  }

  &--light {
    + label {
      color: $c-white;
    }
  }
}
