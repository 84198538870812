.c-section-header {
  @include row;
  margin-bottom: $spacing-large;

  @include breakpoint($bp-large) {
    margin-bottom: $spacing-xlarge;
  }

  &__subheading {
    @include breakpoint($bp-medium) {
      font-size: $font-size-4;
    }
  }

  &__main {
    text-align: center;

    @include span-columns(12);

    @include breakpoint($bp-large) {
      @include span-columns(5);
      @include shift(3.5);
    }
  }

  &--left-aligned {
    .c-section-header__main {
      text-align: left;

      @include breakpoint($bp-large) {
        @include span-columns(5);
        @include shift(0);
      }
    }
  }
}
