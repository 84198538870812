.c-social {
  li {
    display: inline-block;
    margin: 0 2px;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: $c-white;

    path {
      fill: $c-white;
    }
  }

  &--chunky {
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    background-color: rgba(palette(grey, xx-light), 0.08);
    padding: $spacing-base;

    @include breakpoint($bp-large) {
      padding: $spacing-medium;
    }

    li a {
      align-items: center;
      display: flex;
      font-size: $font-size-4;
      justify-content: space-between;
      text-decoration: none;

      @include breakpoint($bp-large) {
        font-size: $font-size-4;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      order: -1;
    }

    svg {
      height: $spacing-large;
      margin-left: $spacing-medium;
      width: $spacing-large;

      @include breakpoint($bp-large) {
        margin-left: $spacing-large;
      }
    }
  }
}
