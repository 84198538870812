@font-face {
  font-family: 'Maragsa';
  src: url('../../fonts/maragsa-display.woff2') format('woff2'),
    url('../../fonts/maragsa-display.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Lato Bold 26px
.t-lato-large-bold,
%t-lato-large-bold {
  font-size: $font-size-4;
  font-weight: $font-weight-bold;

  @include breakpoint($bp-medium) {
    font-size: $font-size-2;
  }
}

// Lato Bold 18px
.t-lato-mid-bold,
%t-lato-mid-bold {
  font-size: $font-size-3;
  font-weight: $font-weight-bold;
}

// Lato Regular 18px
.t-lato-mid,
%t-lato-mid {
  font-family: $lato;
  font-size: $font-size-5;
  font-weight: $font-weight-bold;
}

// Lato Italic 18px
.t-lato-mid-italic,
%t-lato-mid-italic {
  font-size: $font-size-5;
  font-style: italic;
}

// Lato Black 18px
.t-lato-mid-heavy,
%t-lato-mid-heavy {
  text-transform: uppercase;
  font-weight: $font-weight-black;
}

// Lato Black 14px
.t-lato-small-heavy,
%t-lato-small-heavy {
  font-size: $font-small;
  text-transform: uppercase;
  font-weight: $font-weight-black;
}

// Merriweather 32px
.t-merriweather-xlarge,
%t-merriweather-xlarge {
  font-family: $merriweather;
  font-weight: $font-weight-bold;
  font-size: 32px;
  line-height: 1.3;
}

// Merriweather Light 32px
.t-merriweather-xlarge-light,
%t-merriweather-xlarge-light {
  font-family: $merriweather;
  font-size: 32px;
  font-weight: $font-weight-light;
  line-height: 1.3;
}

// Merriweather Light 26px
.t-merriweather-large-light,
%t-merriweather-large-light {
  font-family: $merriweather;
  font-size: $font-size-2;
  font-weight: $font-weight-light;
}

// Merriweather Light 24px
.t-merriweather-mid-large-light,
%t-merriweather-mid-large-light {
  font-size: $font-size-5;
  font-family: $merriweather;
  font-weight: $font-weight-light;

  @include breakpoint($bp-large) {
    font-size: $font-size-3;
  }
}

// Merriweather Light 18px
.t-merriweather-mid,
%t-merriweather-mid {
  font-size: $font-size-5;
  font-family: $merriweather;
}

// Georgia 22px
.t-georgia-large,
%t-georgia-large {
  font-family: $georgia !important;
  font-size: $font-size-4;
  font-weight: $font-weight-semi-bold !important;

  @include breakpoint($bp-large) {
    font-size: $font-size-3;
  }
}

// Georgia Regular 22px
.t-georgia-mid-large,
%t-georgia-mid-large {
  font-family: $georgia !important;
  font-size: $font-size-4;
}

// Georgia Italic 18px
.t-georgia-mid,
%t-georgia-mid {
  font-family: $georgia;
  font-size: $font-size-5;
  font-style: italic;
}
