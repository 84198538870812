.c-rating {
    color: palette(yellow);
    font-family: $merriweather;
    font-size: 14px;
    position: relative;
    top: 18px;
    width: 100%;

    @include breakpoint($bp-large) {
        display: block;
        position: absolute;
        left: 0;
        width: 30%;
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
    }
    
    svg {
        width: 20px;
        height: 20px;
        position: relative;
        top: 4px;
        
        @include breakpoint($bp-medium) {
            top: 3px;
        }      
        
        &.c-rating__star-first {
            margin-left: .2em;
        }
        
        &.c-rating__star-last {
            margin-right: .2em;
        }
    }

    br {
        display: none;

        @include breakpoint($bp-large) {
            display: block;
        }
    }
}