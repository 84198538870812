.c-select {
    background-color: #f1f1f1;
    border-radius: $global-radius;
    border: 1px solid #eee;
    color: $c-font;
    cursor: pointer;
    display: block;
    font-size: toEms(18px);
    margin: 0;
    padding: 0;
    position: relative;

    &.parsley-error {
        border-color: palette(errors);
    }

    &:before {
        @extend %i-chevron-down;
        color: palette(grey);
        display: block;
        font-size: 16px;
        line-height: 1;
        position: absolute;
        right: $spacing-base;
        top: 50%;
        transform: translateY(-50%);
        height: 16px;

        .lt-ie10 & {
            display: none;
        }
    }

    // Hide native arrow in IE
    select::-ms-expand {
        display: none;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: transparent;
        background-image: none;
        border: none;
        box-shadow: none;
        color: $c-font;
        cursor: pointer;
        font-size: $font-size-5;
        line-height: normal;
        line-height: 1.6;
        padding: 10px $spacing-small;
        position: relative;
        width: 100%;

        .lt-ie10 & {
            padding: 11px;
        }
    }
}
