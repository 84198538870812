.u-ui-header {
	background: #333;
	color: $c-white;
	padding-bottom: 30px;
	padding-top: 80px;
	position: relative;

	#{&}-logo {
		position: absolute;
		right: 30px;
		top: 30px;

		img {
			width: 80px;
		}
	}
}

%ui-heading {
	border-bottom: 1px solid tint($c-black, 75%);
	color: tint($c-black, 50%);
	font-weight: 400;
	line-height: 1;
	margin-bottom: 50px;
	margin-top: 0;
}

.u-ui-heading-large {
	@extend %ui-heading;
	border: none;
	color: $c-white;
	font-size: toRems(42px);
	font-weight: 600;
	line-height: 1.3;
	margin-bottom: 0;
}

.u-ui-heading-medium {
	@extend %ui-heading;
	font-size: toRems(30px);
	padding-bottom: 10px;
}

.u-ui-heading-small {
	@extend %ui-heading;
	font-size: toRems(21px);
	line-height: 1;
	padding-bottom: 10px;
	margin: 30px 0;
}

// Color swatches
.u-ui-color {
	@include omega(5n);
	@include span-columns(4.8 of 24);
	border-radius: 3px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	font-size: toRems(14px);
	line-height: 1.3;
	margin-bottom: 20px;
	overflow: hidden;
	padding-top: 150px;

	> div {
		background: $c-white;
		padding: 10px;
	}

	h4 {
		font-size: toRems(14px);
		font-weight: 400;
		margin: 0;
	}
}

// Sections
.u-ui-section {
	padding: 50px 0;
}

.u-ui-section-medium {
	background: #eee;

	[class^="ui-heading"] {
		color: tint($c-black, 35%);
		border-color: tint($c-black, 35%);
	}
}

.u-ui-section-dark {
	background: #666;

	[class^="ui-heading"] {
		color: $c-white;
		border-color: $c-white;
	}
}

.u-ui-bg-wrap {
	display: inline-block;
	padding: 15px;
}

.u-ui-code-wrap {
	background-color: #666;
	color: #ffffff;
	display: inline-block;
	font-family: monospace;
	font-size: toRems(14px);
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	margin-left: 20px;
	padding: 10px 15px;
	text-transform: none;
	vertical-align: middle;
}

.u-ui-note {
	background-color: #754ACC;
	color: $c-white;
	display: block;
	margin: 30px 0;
	padding: 25px;

	&:before {
		content: "DEVELOPER NOTE*";
		display: block;
		font-size: toRems(14px);
		font-weight: $font-weight-bold;
		margin-bottom: 10px;
		text-decoration: underline;
	}

	a {
		color: $c-white;
		text-decoration: underline;
	}

	.u-ui-code-wrap {
		background-color: #fff;
		color: #754ACC;
		margin-left: 10px;
		margin-right: 10px;
	}
}
