.c-section-subtitle {
  @extend %t-merriweather-mid-large-light;
  color: palette(grey, light);
  font-weight: $font-weight-normal;
  text-align: center;
  margin: $spacing-medium 0;

  @include breakpoint($bp-medium) {
    margin: $spacing-medium $spacing-small;
  }

  @include breakpoint($bp-large) {
    margin: $spacing-large;
  }

  &--no-bottom-margin {
    margin-bottom: 0;
  }

  &--no-top-margin {
    margin-top: 0 !important;
  }

  &--left-aligned {
    text-align: left;
    margin-left: 0;
  }
}
