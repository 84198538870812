.c-accordion {
  input {
    position: absolute;
    left: -9999px;

    &:checked {
      + .c-accordion__button {
        color: $c-white;

        .c-icon svg {
          fill: $c-white;
          transform: rotate(180deg);
        }
      }

      ~ .c-accordion__content {
        max-height: 800px;
        padding: $spacing-medium $spacing-base;
      }
    }
  }

  &__button {
    background: transparent;
    border-bottom: 1px solid palette(grey, dark);
    border-top: 1px solid palette(grey, dark);
    color: palette(grey, x-light);
    cursor: pointer;
    display: flex;
    font-size: $base-font-size;
    justify-content: space-between;
    margin-bottom: -1px;
    padding: $spacing-base $spacing-large $spacing-base $spacing-small;
    position: relative;
    transition: color 0.3s ease-in-out;

    > span {
      pointer-events: none;
    }

    @include breakpoint($bp-large) {
      font-size: $font-size-5;
    }

    &:hover {
      color: $c-white;

      .c-icon svg {
        fill: $c-white;
      }
    }

    .c-icon {
      position: absolute;
      right: $spacing-base;
      top: 50%;
      transform: translateY(-50%);
      width: $spacing-xsmall;

      svg {
        fill: palette(grey, light);
        height: $spacing-xsmall;
        transition: all 0.26s ease-in-out;
        width: $spacing-xsmall;
      }
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    padding: 0 $spacing-base;
    transition: all 0.35s;

    a {
      color: $c-white;
      text-decoration: underline;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
