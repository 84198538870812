.c-menu-compact {
  margin: 0 auto $spacing-medium;
  padding: 0 0 $spacing-medium 0;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 1px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    background-color: palette(grey, x-light);
  }

  &__list {
    h4 {
      @extend %t-merriweather-large-light;
      margin-bottom: 0;
      font-size: toRems(20px);

      @include breakpoint($bp-large) {
        font-size: $font-size-4;
      }

      span {
        font-family: $lato;
        font-size: $font-small;
        font-weight: $font-weight-bold;
      }
    }

    li {
      margin-bottom: $spacing-base;
    }

    .c-menu-compact__info {
      @include breakpoint($bp-large) {
        font-size: toRems(18px);
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  > p {
    @include breakpoint($bp-large) {
      font-size: toRems(18px);
    }
  }

  &__title {
    @extend %t-lato-large-bold;
    margin-bottom: $spacing-base;

    // + p:not(.c-menu-compact__summary) {
    //   @extend %t-lato-small-heavy;
    //   font-size: toRems(14px);
    //   color: palette(grey, mid-light);

    //   @include breakpoint($bp-large) {
    //     margin-bottom: $spacing-large;
    //   }
    // }

    + .c-menu-compact__summary {
      @include breakpoint($bp-large) {
        font-size: toRems(18px);
        margin-bottom: $spacing-large;
      }
    }
  }

  > li {
    position: relative;

    &:last-child {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }

    &:after {
      @extend %t-merriweather-mid;
      content: "•";
      position: absolute;
      color: palette(pink);
      left: 50%;
      bottom: -50px;
    }
  }

  &__info {
    color: palette(grey, light);
    position: relative;

    @include breakpoint($bp-medium-max) {
      font-size: toRems(15px) !important;
    }
  }
}
