/**
 * Good old fashioned full width image slideshow
 */

.c-image-slideshow {
  width: 100%;
  min-height: 600px;

  &--dots-left {
    @include breakpoint($bp-large) {
      .flickity-page-dots {
        text-align: left;
        left: $spacing-large;
      }
    }
  }

  &--dots-right {
    @include breakpoint($bp-large) {
      .flickity-page-dots {
        text-align: right;
        right: $spacing-large;
      }
    }
  }

  &--alt-arrows {
    .flickity-prev-next-button {
      height: 70px;
      width: 70px;
      background-color: transparent;

      svg path {
        fill: $c-white;
      }
    }
  }

  &__item {
    height: 600px;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;

    &.is-selected {
      opacity: 1;
    }
  }

  .flickity-page-dots {
    bottom: 15px;
    display: none;

    @include breakpoint($bp-large) {
      display: block;
    }

    .dot {
      background-color: $c-white;

      &.is-selected {
        background-color: $c-black;
        transform: scale(1);
      }
    }
  }
}
