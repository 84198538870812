.c-icon-social {
    svg {
        transition: fill 0.3s $global-easing;
        
        &:hover {
            fill: $c-font !important; 
        }
    }
	&[class*="facebook"] svg {
		fill: $c-facebook;
	}
	&[class*="google"] svg {
		fill: $c-google-plus;
	}
	&[class*="instagram"] svg {
		fill: $c-instagram;
	}
	&[class*="linkedin"] svg {
		fill: $c-linked-in;
	}
	&[class*="pinterest"] svg {
		fill: $c-pinterest;
	}
	&[class*="twitter"] svg {
		fill: $c-twitter;
	}
	&[class*="youtube"] svg {
		fill: $c-youtube;
	}
    &[class*="mail"] svg {
		fill: palette(grey);
	}
}
