.s-content-blocks {
  .o-section {
    + .o-section {
      padding-top: 0;
    }

    .c-video {
      margin-bottom: 0;
    }
  }
}
