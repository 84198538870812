// Colour palletes

$palettes: (
    grey: (
        xx-light: #F3F3F3,
        x-light: #D8D8D8,
        mid-light: #949494,
        light: #868686,
        base: #525252,
        mid-dark: #404040,
        dark: #333333,
        x-dark: #222222,
        xx-dark: #181818,
    ),
    pink: (
        base: #EF465A,
        dark: #C82E40
    ),
    yellow: (
        base: #F9F07E
    ),
    orange: (
        base: #f48f13,
        dark: #c7740f
    ),
    errors: (
        base: #f00f00
    ),
    border: (
        base: #d6d8da
    )
);

// Global colors
$c-white: #ffffff;
$c-black: #000000;
$c-link: #754ACC;
$c-link-hover: palette(grey, light);
$c-link-visited: palette(grey, light);
$c-font: palette(grey, dark);
$c-placeholder: palette(grey);
$c-border: palette(grey);

// Text highlight colors
$text-highlight-color: $c-white;
$text-highlight-bg-color: palette(grey);
$tap-highlight-color: rgba(255,255,255, 0);

// Social colors
$c-facebook: #3b5998;
$c-google-plus: #dd4b39;
$c-instagram: #125688;
$c-linked-in: #007bb5;
$c-pinterest: #cb2027;
$c-twitter: #00aced;
$c-youtube: #bb0000;
