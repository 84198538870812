.c-site-info {
  font-size: $font-small;
  font-family: var(--site-info-font-family);
  color: palette(grey, light);
  text-align: right;
  padding: $spacing-xsmall;
  text-transform: lowercase;
  display: flex;

  p {
    margin: 0;

    &:last-child {
      margin-left: auto;
    }
  }

  a {
    color: palette(grey, light);

    &:hover {
      color: palette(grey);
    }
  }
}
