.c-grid-image {
  border-radius: 4px;
  position: relative;
  display: block;
  overflow: hidden;

  .c-icon {
    position: absolute;
    top: $spacing-xsmall;
    left: $spacing-xsmall;
    z-index: 2;
    transition: all 0.26s ease-in-out;

    svg {
      fill: $c-white;
      position: absolute;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.26s ease-in-out;
  }

  &:hover {
    box-shadow: 0 0 0 3px $c-white;

    .c-icon {
      top: $spacing-small;
      left: $spacing-small;
      transform: scale(1.1);
    }

    img {
      transform: scale(1.05) rotate(0.5deg);
    }
  }
}
