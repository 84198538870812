.c-section-title {
  font-size: var(--section-title-font-size);
  font-weight: var(--section-title-font-weight);
  text-align: center;
  text-transform: uppercase;
  padding-bottom: $spacing-medium;
  position: relative;
  margin-bottom: 1em;

  @include breakpoint($bp-large) {
    margin-bottom: var(--section-title-margin-bottom-desktop);
    font-size: var(--section-title-font-size-desktop);
  }

  &:after {
    background-color: var(--section-title-line-color);
    bottom: 0;
    content: '';
    height: 2px;
    left: 50%;
    margin-left: -50px;
    position: absolute;
    width: 100px;
  }

  &--light {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);

    &:after {
      background-color: $c-white;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    }
  }

  &--left-aligned {
    text-align: left;

    &:after {
      left: 0;
      margin-left: 0;
    }
  }

  &--small {
    font-size: var(--section-title-font-size-small) !important;
  }
}
