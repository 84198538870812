.c-nav {
  max-height: 0;
  overflow: hidden;
  text-transform: var(--nav-text-transform);
  font-weight: var(--nav-font-weight);
  transition: max-height 400ms ease-in-out;

  @include breakpoint($bp-large) {
    font-size: $font-xsmall;
    margin-left: auto;
    display: block;
    max-height: none;
    overflow: visible;
  }

  @include breakpoint($bp-xlarge) {
    font-size: $base-font-size;
  }

  &.is-active {
    max-height: 500px;

    li {
      opacity: 1;
    }
  }

  ul {
    padding-top: $spacing-small;
    transition: all 400ms ease-in-out;

    @include breakpoint($bp-large) {
      padding-top: 0;
      width: 100%;
      margin: 0 auto;
    }
  }

  li {
    opacity: 0;
    transition: opacity 400ms ease-in-out 100ms;
    border-bottom: var(--nav-list-item-border-bottom-mobile);

    @include breakpoint($bp-large) {
      border-bottom: none;
      display: inline-block;
      opacity: 1;
      padding: 0 10px;
    }

    &:first-child {
      a {
        @include breakpoint($bp-large) {
          padding-left: 0;
        }
      }
    }

    &:last-child {
      border-bottom: none;

      @include breakpoint($bp-large) {
        padding-right: 0;
      }

      a {
        @include breakpoint($bp-large) {
          padding-right: 0;
        }
      }
    }
  }

  a {
    color: var(--nav-link-color-mobile);
    padding: $spacing-xsmall $spacing-xsmall $spacing-xsmall 0;
    display: block;

    @include breakpoint($bp-large) {
      padding: 0;
      color: $c-white;
      position: relative;

      &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: -2px;
        left: 50%;
        width: 0;
        height: 1px;
        background-color: $c-white;
        transition: all 260ms $trans-ease-out-back;
      }

      &:hover,
      &.is-active {
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  &--centered {
    margin-right: auto;

    ul {
      max-width: 100%;

      @include breakpoint($bp-large) {
        text-align: center;
      }
    }

    a {
      font-weight: $font-weight-normal;

      @include breakpoint($bp-large) {
        text-align: center;
      }
    }
  }

  .c-top-bar--inner-page & {
    margin-left: auto;

    @include breakpoint($bp-large) {
      padding-left: $spacing-large;
    }
  }

  .c-top-bar--alt & {
    ul {
      @include breakpoint($bp-large) {
        text-align: center;
      }
    }
  }
}
