/**
 * This is override for /plugins/flickity.scss
 */

.flickity-page-dots {
  .dot {
    height: 10px;
    width: 10px;
    background-color: var(--flickity-dots-color);
    opacity: 1;
    margin: 0 4px;
    transition: all 300ms ease-in-out;

    &.is-selected {
      background-color: var(--flickity-dots-selected-color);
      transform: scale(1);
    }
  }
}
