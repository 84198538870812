.o-slideshow {
  @include breakpoint($bp-large) {
    margin-bottom: 10px;
  }

  .flickity-page-dots {
    // display: none;
    bottom: 20px;

    @include breakpoint($bp-large) {
      display: flex;
    }
  }

  &--dots-lower {
    .flickity-page-dots {
      bottom: -30px;

      @include breakpoint($bp-large) {
        bottom: -40px;
      }
    }
  }

  &.has-one-slide {
    pointer-events: none; // this is a bit of a hack, need to stop the slider being draggable

    .flickity-page-dots,
    .flickity-prev-next-button {
      display: none;
    }
  }

  &--dots-left {
    @include breakpoint($bp-large) {
      .flickity-page-dots {
        text-align: left;
        left: $spacing-large;
      }
    }
  }

  &--dots-right {
    @include breakpoint($bp-large) {
      .flickity-page-dots {
        justify-content: flex-end;
        width: auto;
        text-align: right;
        left: 30%; // Room for the arrows
        right: $spacing-large;
      }
    }
  }

  &--dots-inside {
    @include breakpoint($bp-large) {
      margin-bottom: 0;
    }

    .flickity-page-dots {
      bottom: 15px;
      display: none;

      @include breakpoint($bp-large) {
        display: flex;
      }

      // .dot {
      //     background-color: #f1f1f1;
      //
      //     &.is-selected {
      //         background-color: $c-black;
      //         transform: scale(1);
      //     }
      // }
    }
  }

  &--alt-arrows {
    .flickity-prev-next-button {
      height: 54px;
      width: 54px;
      background-color: transparent;

      svg path {
        fill: $c-white;
      }
    }
  }

  &--arrows-bottom-left {
    .flickity-prev-next-button {
      @include breakpoint($bp-large) {
        bottom: -88px;
        top: auto;
      }

      &.previous {
        @include breakpoint($bp-large) {
          left: -$spacing-small;
        }
      }

      &.next {
        @include breakpoint($bp-large) {
          left: $spacing-medium;
          right: auto;
        }
      }
    }
  }

  &--hide-arrows-on-mobile {
    .flickity-prev-next-button {
      @include breakpoint($bp-medium-max) {
        display: none;
      }
    }
  }

  &__item {
    width: 100%;
    padding: 0;
    max-height: 60vh;
    position: absolute;
    // opacity: .4;
    // transition: opacity 600ms ease-in-out;
    background-size: cover;
    //
    // @include breakpoint($bp-large) {
    //     padding: 0 $spacing-large;
    // }

    figure {
      max-height: 60vh;

      img {
        max-height: 60vh;
        min-width: 100%;
        object-fit: cover;
      }
    }

    &.is-selected {
      // opacity: 1;
    }
  }

  &--thirds {
    .o-slideshow__item {
      width: 33.3%;
      margin: 0 $spacing-small 0 0;
    }
  }

  &--overflow-showing {
    // overflow: hidden;

    .flickity-viewport {
      overflow: visible;
    }

    .o-slideshow__item {
      opacity: 0.4;
      // transition: opacity 600ms ease-in-out;

      &.is-selected {
        opacity: 1;
      }
    }
  }

  &--rounded {
    .flickity-viewport {
      border-radius: 4px;
    }
  }
}
