.c-share {
    display: block;
    margin: 0 0 20px 0;
    position: relative;
    width: 40px;

    @include breakpoint($bp-xlarge) {
        margin: 0;
    }

    &__btn {
        display: inline-block;

        &:focus {
            outline: none;
        }

        @include breakpoint($bp-xlarge) {
            display: block;
        }

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &__drawer {
        display: inline-block;
        left: 100%;
        position: absolute;
        text-align: left;
        top: 50%;
        padding-left: 15px;
        transform: translateY(calc(-50% - 6px));
        transition: opacity 0.5s $global-easing, visibility 0.5s $global-easing;
        vertical-align: middle;
        width: 320px;

        @include breakpoint($bp-xlarge) {
            display: block;
            left: auto;
            padding-left: 0;
            // padding-top: 15px;
            position: relative;
            text-align: center;
            top: auto;
            transform: none;
            width: auto;

            .js & {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__item {
        display: inline-block;
        margin-right: 10px;
        transition: opacity 0.5s $global-easing, transform 0.7s $global-easing;
        vertical-align: middle;

        @include breakpoint($bp-xlarge) {
            display: block;
            margin-bottom: 2px;
            margin-right: 0;
            opacity: 0;
            transform: translateY(-20px);
        }

        a[class*="addthis"] {
            color: transparent;
            font-size: 0;

            > span {
                display: none !important;
            }

            svg {
                height: 18px;
                width: 18px;
            }

            &[class*="facebook"] {
                padding-right: 3px;

                svg {
                    height: 20px;
                    width: 20px;
                }
            }

            &[class*="twitter"] {
                position: relative;
                top: 2px;
                svg {
                    width: 22px;
                    height: 22px;
                }
            }

            &[class*="google_plus"] {
                position: relative;
                top: 2px;
                padding-left: 3px;

                svg {
                    width: 22px;
                    height: 22px;
                }
            }

            // Have to use font icon for pinterest addThis removes svg markup
            &[class*="pinterest"] {

                &:hover:after {
                    color: $c-pinterest;
                }

                &:after {
                    @extend %i-pinterest;
                    color: palette(grey, dark);
                    font-size: 20px;
                    transition: color 0.3s $global-easing;
                }
            }
        }
    }


    @include breakpoint($bp-xlarge) {
        position: absolute;
        left: -70px;
        top: 5px;
    }

    &.is-open {
        .js & {
            .c-share__drawer {
                opacity: 1;
                visibility: visible;
            }

            .c-share__item {
                opacity: 1;
                transform: translateY(0);

                @for $i from 1 through 7 {
                    &:nth-child(#{$i + 1}) {
                        transition-delay: 0.1s * $i;
                    }
                }
            }
        }
    }
}
