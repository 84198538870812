.c-text-listing {
  dt {
    @extend %t-merriweather-mid;
    color: palette(grey, mid-light);
  }

  dd {
    padding: 0;
    margin: 0 0 1em;

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint($bp-medium) {
      margin: 0 0 2.5em;
    }
  }
}
